<template>
  <div class="view-fusion">
    <BackHeader title="熔炼" />
    <div class="animation-box">
      <div class="bg3 flex-c">
        <img
          style="height: 100%"
          src="@/assets/images/new/fusion-bg3.png"
          alt=""
          srcset="" />
      </div>
      <div v-if="showGif" class="bg2 flex-c">
        <img style="height: 100%" :src="srcUrl" alt="" srcset="" />
      </div>
      <div ref="bg5" class="bg5 flex-c" v-if="cacheFusionItem.cover">
        <img
          style="height: 100%"
          :src="cacheFusionItem.cover"
          alt=""
          srcset="" />
      </div>
      <div class="bg4 flex-c" v-if="showBg4">
        <img
          style="height: 100%"
          src="@/assets/images/new/fusion-bg4.png"
          alt=""
          srcset="" />
      </div>
    </div>
    <div class="tab-box">
      <div
        v-for="(item, index) in headerTabList"
        @click="changeTabOnClick(index)"
        :key="index"
        class="tab-item"
        :class="{ 'tab-item-active': index == tabIndex }">
        {{ item.title }}
      </div>
    </div>
    <div v-if="tabIndex == 0" class="content-box">
      <div v-if="!chooseGoodsList.length" class="empty-box">
        <img
          class="img"
          src="@/assets/images/newuser/mb-table-nulldata.png"
          alt="" />
        <div class="nulltext">暂无内容</div>
      </div>
      <div v-else class="choosed-box">
        <div
          v-for="(item, index) in chooseGoodsList"
          :key="item.id"
          class="choosed-item">
          <GoodItem
            @closeOnClick="emitCloseOnClick(index)"
            showCloseBtn
            :item="item"
            :isActive="chooseGoodsIdList.includes(item.id)" />
        </div>
      </div>
    </div>
    <div v-else class="content-box content-box2">
      <div class="select-list flex-b">
        <div class="select-item">
          <MySelect v-model="select1" :selectList="select1List" />
        </div>
        <div class="select-item">
          <MySelect v-model="select2" :selectList="select2List" />
        </div>
      </div>
      <div v-if="!fusionGoodList.length" class="empty-box">
        <img
          class="img"
          src="@/assets/images/newuser/mb-table-nulldata.png"
          alt="" />
        <div class="nulltext">暂无内容</div>
      </div>
      <div v-else class="choosed-box">
        <div
          @click="fusionGoodOnClick(item)"
          v-for="item in fusionGoodList"
          :key="item.id"
          class="choosed-item">
          <GoodItem :isActive="fusionItem.id === item.id" :item="item" />
        </div>
      </div>
    </div>
    <div class="bottom-box flex-b">
      <div class="btn flex-c" @click="chooseGoodsOnClick">选择道具</div>
      <div class="btn flex-c" @click="fusionBtnOnClick">熔炼</div>
    </div>
    <van-overlay :show="popupShow">
      <div class="my-backpack">
        <div @click="closeOnClick" class="close-btn"></div>
        <div class="title">我的背包</div>
        <div class="bg-line bg-line1"></div>
        <div class="bg-line bg-line2"></div>
        <div @click="chooseAllOnClick" class="choose-all-box flex-c">
          <div class="check-box">
            <img
              v-show="!isSelectAll"
              src="@/assets/images/about/bag/mb-check.png"
              alt=""
              style="width: 0.12rem; margin-right: 0.05rem" />
            <img
              v-show="isSelectAll"
              src="@/assets/images/about/bag/mb-checked.png"
              alt=""
              style="width: 0.12rem; margin-right: 0.05rem" />
          </div>
          <div class="check-text">全选当前页</div>
        </div>
        <div class="backpack-box">
          <div
            v-for="item in backpackList"
            :key="item.id"
            class="backpack-item"
            @click="chooseItemOnClick(item)">
            <GoodItem
              :item="item"
              :isActive="chooseGoodsIdList.includes(item.id)" />
          </div>
        </div>
        <div class="page-box">
          <Pagination
            :total="page_total"
            :page.sync="page_num"
            :limit.sync="per_page"
            @pagination="handleCurrentChange" />
        </div>
        <div class="bottom-btn-box flex-c">
          <div @click="chooseOnClick" class="btn-box">确定选择</div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import GoodItem from "./components/goodItem.vue";
import {
  Storage,
  fusionGoodListApi,
  luckyType,
  newOldType,
  fusionApi,
} from "@/network/api.js";
import { uniqBy } from "lodash";
export default {
  components: {
    GoodItem,
  },
  data() {
    return {
      headerTabList: [
        {
          icon: "icon-foo1.png",
          title: "材料包",
          href: "/Lucky",
        },
        {
          icon: "icon-foo2.png",
          title: "置换饰品",
          href: "/Battle",
        },
      ],
      tabIndex: 0,
      popupShow: false,
      page_num: 1,
      per_page: 12,
      page_total: 1,
      backpackList: [],
      select1: "all",
      select1List: [],
      select2: "all",
      select2List: [],
      chooseGoodsList: [],
      cacheChooseGoodsList: [],
      showGif: false,
      showBg4: false,
      srcUrl: "",
      fusionGoodList: [],
      fusionItem: {},
      cacheFusionItem: {},
    };
  },
  created() {
    this.getLuckyType();
    this.getNewOldType();
  },
  computed: {
    chooseGoodsIdList() {
      return this.chooseGoodsList.map((item) => item.id);
    },
    isSelectAll() {
      let flag = true;
      this.backpackList.forEach((item) => {
        if (!this.chooseGoodsIdList.includes(item.id)) {
          flag = false;
        }
      });
      return flag;
    },
  },
  watch: {
    chooseGoodsIdList: {
      handler() {
        this.fusionItem = {};
        // this.getFusionGoodList();
      },
      deep: true,
      immediate: true,
    },
    select1: {
      handler() {
        this.getFusionGoodList();
      },
    },
    select2: {
      handler() {
        this.getFusionGoodList();
      },
    },
  },
  methods: {
    chooseAllOnClick() {
      if (this.isSelectAll) {
        this.chooseGoodsList = this.chooseGoodsList.filter((item) => {
          return !this.backpackList
            .map((vItem) => {
              return vItem.id;
            })
            .includes(item.id);
        });
      } else {
        this.chooseGoodsList = uniqBy(
          [...this.chooseGoodsList, ...this.backpackList],
          "id"
        );
      }
    },
    async getLuckyType() {
      const res = await luckyType();
      this.select1List = res.data.data.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      this.select1List.unshift({
        label: "全部",
        value: "all",
      });
    },
    async getNewOldType() {
      const res = await newOldType();
      this.select2List = res.data.data.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      this.select2List.unshift({
        label: "全部",
        value: "all",
      });
    },
    closeOnClick() {
      this.chooseGoodsList = this.cacheChooseGoodsList;
      this.popupShow = false;
    },
    async chooseOnClick() {
      if (this.chooseGoodsList.length < 2) {
        this.$message({
          message: "熔炼商品数量必须大于1件",
          type: "error",
        });
        return false;
      }
      if (this.chooseGoodsList.length > 20) {
        this.$message({
          message: "熔炼商品数量必须小于或等于20件",
          type: "error",
        });
        return false;
      }
      await this.getFusionGoodList();
      this.popupShow = false;
    },
    fusionGoodOnClick(item) {
      console.log(123, item);
      this.fusionItem = item;
    },
    async changeTabOnClick(index) {
      this.tabIndex = index;
    },
    async getFusionGoodList() {
      if (this.chooseGoodsIdList.length < 2) {
        return false;
      }
      const params = {
        type: this.select1 === "all" ? "" : this.select1,
        dura: this.select2 === "all" ? "" : this.select2,
        in_ids: this.chooseGoodsIdList,
      };
      const res = await fusionGoodListApi(params);
      console.log(3333, res.data);
      if (res.data?.code === 200) {
        this.fusionGoodList = res?.data?.data || [];
      } else {
        this.$message({
          message: res.data.message,
          type: "error",
        });
      }
    },
    async fusionIngFun() {
      const params = {
        in_ids: this.chooseGoodsIdList,
        skins_id: this.fusionItem.id,
        // skins_id: 7455,
      };
      const res = await fusionApi(params);
      if (res?.data?.code === 200) {
        this.$message.success("熔炼成功");
        return true;
      } else {
        this.$message.error(res?.data?.message);
        return false;
      }
    },
    palyFusionAudio() {
      // 创建一个新的Audio对象
      var audio = new Audio(require("@/assets/audios/ronglian.wav"));
      audio.play();
    },
    async fusionBtnOnClick() {
      if (!this.fusionItem.id) {
        this.$message({
          message: "请选择要融合的物品",
          type: "error",
        });
        return false;
      }
      const res = await this.fusionIngFun();
      if (!res) {
        return false;
      }
      this.palyFusionAudio();
      this.showGif = true;
      this.srcUrl = require("../../assets/images/new/fusion-bg1.gif");
      setTimeout(() => {
        this.srcUrl = require("../../assets/images/new/opycity0.png");
        this.showBg4 = true;
        this.chooseGoodsList = [];
        this.fusionGoodList = [];
        this.fusionItem = {};
        this.changeTabOnClick(1);
      }, 1000);
      setTimeout(() => {
        document.querySelector(".bg5")?.classList.remove("expand");
        document.querySelector(".bg5").classList.remove("float-ud");
      }, 0);
      setTimeout(() => {
        document.querySelector(".bg5").classList.add("expand");
      }, 500);
      setTimeout(() => {
        document.querySelector(".bg5").classList.add("float-ud");
      }, 1600);
      this.cacheFusionItem = this.fusionItem;
    },
    emitCloseOnClick(index) {
      this.chooseGoodsList.splice(index, 1);
    },
    chooseItemOnClick(item) {
      if (!this.chooseGoodsIdList.includes(item.id)) {
        if (this.chooseGoodsIdList.length >= 20) {
          this.$message({
            message: "最多选择20个物品",
            type: "error",
          });
          return false;
        }
        this.chooseGoodsList.push(item);
      } else {
        this.chooseGoodsList = this.chooseGoodsList.filter(
          (vItem) => vItem.id !== item.id
        );
      }
    },
    handleCurrentChange(e) {
      this.page = e.page;
      this.getStorageFun();
    },
    chooseGoodsOnClick() {
      this.cacheChooseGoodsList = JSON.parse(
        JSON.stringify(this.chooseGoodsList)
      );
      this.backpackList = [];
      this.popupShow = true;
      this.getStorageFun();
    },
    getStorageFun() {
      Storage(this.page_num, 1, 0, this.per_page).then((res) => {
        console.log(res);
        this.backpackList = res.data.data.data;
        this.page_total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="scss">
.view-fusion {
  width: 100%;
  padding: 0.18rem 0.18rem;
  .animation-box {
    width: 100%;
    height: 2.44rem;
    position: relative;
    .bg3 {
      width: 3.75rem;
      height: 3.2rem;
      position: absolute;
      left: -0.18rem;
      top: -0.1rem;
    }
    .bg2 {
      width: 3.75rem;
      height: 3.2rem;
      position: absolute;
      left: -0.18rem;
      top: -0.1rem;
    }
    .bg4 {
      width: 3.75rem;
      height: 3.2rem;
      position: absolute;
      left: -0.18rem;
      top: -0.1rem;
    }
    .bg5 {
      width: 100%;
      height: 1.7rem;
      position: absolute;
      left: 0rem;
      top: 0.74rem;
      transform: scale(0);
      transition: transform 1s ease-in-out;
    }
    /* 定义一个名为floatEffect的关键帧动画 */
    @keyframes floatEffect {
      0%,
      100% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(-0.25rem); /* 根据需要调整浮动的距离 */
      }
    }

    /* 应用该动画到想要浮动的元素，例如一个图片 */
    .float-ud {
      animation: floatEffect 2.5s ease-in-out infinite; /* 动画名称、持续时间、速度曲线、循环次数 */
    }
    .expand {
      transform: scale(1);
    }
    // background: #ccc;
  }
  .tab-box {
    width: 3.35rem;
    height: 0.34rem;
    margin: 0 auto;
    margin-top: 0.2rem;
    display: flex;
    align-items: center;
    background: url(../../assets/images/new/header-bg.png) no-repeat;
    background-size: 100% 100%;
    margin-bottom: 0.12rem;
    position: relative;
    z-index: 1;
    .tab-item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      height: 100%;
    }
    .tab-item-active {
      background: url(../../assets/images/new/header-active-bg.png) no-repeat;
      background-size: 120% 182%;
      background-position: -0.159rem -0.1rem;
    }
  }
  .content-box {
    width: 100%;
    margin-bottom: 0.2rem;
    .select-list {
      width: 100%;
      height: 0.22rem;
      //   background: pink;
      margin-bottom: 0.12rem;
      .select-item {
      }
    }
    .empty-box {
      width: 100%;
      height: 3.5rem;
      border: 0.02rem solid #2f2f5e;
      padding: 0.6rem 1.28rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-content: flex-start;
      .img {
        width: 0.8rem;
        height: 0.8rem;
        margin-bottom: 0.1rem;
      }
      .nulltext {
        width: 100%;
        font-size: 0.12rem;
        color: #c5bbed;
        text-align: center;
      }
    }
    .choosed-box {
      padding: 0.12rem 0.11rem 0.12rem 0.12rem;
      width: 100%;
      height: 3.5rem;
      border: 0.02rem solid #2f2f5e;
      align-content: flex-start;
      display: flex;
      //   align-items: center;
      flex-wrap: wrap;
      overflow-y: auto;
      .choosed-item {
        width: 1rem;
        height: 1.02rem;
        margin-right: 0.06rem;
        margin-bottom: 0.06rem;
        flex-shrink: 0;
        &:nth-of-type(3n) {
          margin-right: 0;
        }
      }
    }
  }
  .bottom-box {
    width: 100%;
    padding: 0 0.15rem;
    .btn {
      padding: 0.07rem 0.37rem;
      font-size: 14px;
      background: pink;
      width: fit-content;
      background: linear-gradient(135deg, #242448 0%, #42426d 100%);
      border-radius: 0.02rem;
      border: 0.01rem solid #5a5a9a;
    }
  }
  .my-backpack {
    width: 3.37rem;
    min-height: 5.8rem;
    background: #08081e;
    border: 0.01rem solid #5d5d9b;
    // background: pink;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 0.08rem 0.11rem 0.12rem 0.12rem;
    .choose-all-box {
      margin-bottom: 0.04rem;
      height: 0.26rem;
      font-size: 0.12rem;
      color: #8a8ace;
      display: flex;
      align-items: center;
      justify-content: start;
      width: 100%;
      .choose-all {
        margin-right: 0.08rem;
      }
    }
    .close-btn {
      position: absolute;
      right: 0.12rem;
      top: 0.12rem;
      width: 0.12rem;
      height: 0.12rem;
      background: url(../../assets/images/new/close-btn.png) no-repeat;
      background-size: 100% 100%;
    }
    .title {
      text-align: center;
      margin-bottom: 0.2rem;
      font-weight: 600;
      color: #dfdbff;
      line-height: 0.22rem;
    }
    .bg-line {
      width: 3.25rem;
      height: 0.02rem;
      background: url(../../assets/images/new/line.png) no-repeat;
      background-size: 100% 100%;
      left: 0.05rem;
      top: 0.38rem;
      position: absolute;
    }
    .bg-line2 {
      top: unset;
      bottom: 0.5rem;
    }
    .backpack-box {
      width: 100%;
      height: 4.26rem;
      //   border: 0.02rem solid #2f2f5e;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      overflow-y: auto;
      margin-bottom: 0.18rem;
      align-content: flex-start;
      .backpack-item {
        width: 1rem;
        height: 1.02rem;
        margin-right: 0.06rem;
        margin-bottom: 0.06rem;
        flex-shrink: 0;
        &:nth-of-type(3n) {
          margin-right: 0;
        }
        // .good-item {
        //   width: 100%;
        //   padding: 0.08rem 0.1rem;
        //   font-size: 0.1rem;
        //   text-align: center;
        //   background: url(../../assets/images/new/ronglian-bg1.png) no-repeat;
        //   background-size: 100% 100%;
        //   .dura_alias {
        //     margin-bottom: 0.04rem;
        //     color: #adadad;
        //   }
        //   .img-box {
        //     width: 100%;
        //     height: 0.5rem;
        //     margin-bottom: 0.04rem;
        //     img {
        //       height: 100%;
        //     }
        //   }
        //   .good-title {
        //   }
        // }
      }
    }
    .page-box {
      width: 100%;
      height: 0.18rem;
      //   background: pink;
      margin-bottom: 0.3rem;
    }
    .bottom-btn-box {
      width: 100%;

      .btn-box {
        background: linear-gradient(135deg, #242448 0%, #42426d 100%);
        border-radius: 0.02rem;
        border: 0.01rem solid #5a5a9a;
        padding: 0.03rem 0.32rem;
        font-size: 0.14rem;
        line-height: 0.2rem;
        width: fit-content;
      }
    }
  }
}
</style>
